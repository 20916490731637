/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/naming-convention */
import { datadogLogs } from '@datadog/browser-logs';

export enum LogEvent {
  Login = 'login',
  Registration = 'registration',
  ResetPassword = 'reset password',
  IdP = 'IdP redirection',
  LaunchDarkly = 'LaunchDarkly flag',
}

export enum LogEventStatus {
  Successful = 'successful',
  Info = 'info',
  HandledError = 'handled error',
  UnhandledError = 'unhandled error',
}

const logEventDevelopment = (
  event: LogEvent,
  status: LogEventStatus,
  data?: Record<string, unknown>,
) => {
  console.log(`${event} ${status}`, {
    ...data,
    event,
    event_status: status,
  });
};

const logEvent = (
  event: LogEvent,
  status: LogEventStatus,
  data?: Record<string, unknown>,
) => {
  datadogLogs.logger.log(
    `${event} ${status}`,
    {
      ...data,
      event,
      event_status: status,
    },
    status === LogEventStatus.UnhandledError ? 'error' : 'info',
  );
};

export default process.env.NODE_ENV === 'development'
  ? logEventDevelopment
  : logEvent;
