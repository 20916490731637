import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  ThemeProvider,
  CssBaseline,
  AlertProvider,
  StyledEngineProvider,
} from '@bw/foundry';
import FeatureFlagsProvider from 'providers/featureFlags/FeatureFlagsProvider';
import Routes from './Routes';
import ApiProvider from './ApiProvider';
import './datadog';

/** Top level application component. */
const App = () => (
  <StyledEngineProvider injectFirst>
    <Router basename={process.env.PUBLIC_URL || '/'}>
      <ApiProvider>
        <CssBaseline>
          <ThemeProvider>
            <AlertProvider>
              <FeatureFlagsProvider>
                <Routes />
              </FeatureFlagsProvider>
            </AlertProvider>
          </ThemeProvider>
        </CssBaseline>
      </ApiProvider>
    </Router>
  </StyledEngineProvider>
);

export default App;
