import React, { Suspense, useEffect } from 'react';
import {
  Redirect,
  Route,
  RouteProps,
  useHistory,
  Switch,
} from 'react-router-dom';
import { Security, SecureRoute, useOktaAuth } from '@okta/okta-react';
import type { RestoreOriginalUriFunction } from '@okta/okta-react/bundles/types/OktaContext';
import { OktaAuth } from '@okta/okta-auth-js';
import Navigation from './components/Navigation';
import config from './utils/config';
import preloadLazy from './utils/preloadLazy';
import usabilla from './utils/usabilla';
import DelayLoader from './components/DelayLoader';
import { throttle } from 'lodash';
import returnToURLWhitelist from 'returnToURLWhitelist';
import { datadogLogs } from '@datadog/browser-logs';

// Lazy load routes
const Login = preloadLazy(() => import('./pages/Login'));
const ForgotPassword = preloadLazy(() => import('./pages/ForgotPassword'));
const NewPassword = preloadLazy(() => import('./pages/NewPassword'));
const Registration = preloadLazy(() => import('./pages/Registration'));
const ClockErrorPage = preloadLazy(() => import('./pages/ClockErrorPage'));
const NotFound = preloadLazy(() => import('./pages/NotFound'));

const oktaAuth = new OktaAuth(config.oidc);

const checkLoggedIn = throttle(async () => {
  const hasSession = await oktaAuth.session.exists();
  if (!hasSession || oktaAuth.isLoginRedirect()) {
    return;
  }

  const { tokens } = await oktaAuth.token.getWithoutPrompt({
    responseType: ['id_token', 'token'], // or array of types
  });
  localStorage.removeItem('attempts');
  await oktaAuth.handleLoginRedirect(tokens);
}, 10000);

const DashboardRedirect = () => {
  useEffect(() => {
    (async () => {
      const returnTo = sessionStorage.getItem('returnTo');
      if (returnTo) {
        try {
          const returnToURL = new URL(returnTo);
          if (!returnToURLWhitelist.includes(returnToURL.hostname)) {
            datadogLogs.logger.warn(`Unlisted returnTo url`, {
              data: {
                invalidReturnTo: returnTo,
              },
            });
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.warn('Invalid returnTo url', returnTo);
        }
      }
      const originalUri = returnTo || oktaAuth.getOriginalUri();
      const { authType } = JSON.parse(
        sessionStorage.getItem('loginData') || '{}',
      );
      authType !== 'popup' &&
        (await oktaAuth?.options?.restoreOriginalUri?.(oktaAuth, originalUri));
    })();
  }, []);
  return null;
};

const SlashRedirect = () => {
  const { authState } = useOktaAuth();
  if (!authState) return <DelayLoader delay={1000} />;
  return authState.isAuthenticated ? (
    <DashboardRedirect />
  ) : (
    <Redirect to="/login" />
  );
};

type PreloadableComponent<T = unknown> = React.ComponentType<T> & {
  preload?: () => void;
};

type ModifiedRouteProps = Omit<RouteProps, 'component'> & {
  component?: PreloadableComponent;
  secure?: boolean;
};

export const routeDefinitions: ModifiedRouteProps[] = [
  { path: '/', exact: true, component: SlashRedirect },
  { path: '/forgot-password', exact: true, component: ForgotPassword },
  { path: '/clock-error', exact: true, component: ClockErrorPage },
  { path: '/new-password', exact: true, component: NewPassword },
  { path: '/registration', exact: true, component: Registration },
  { path: '/login', component: Login },
  { path: '/logout', render: () => <Redirect to="/login" /> },
  { component: NotFound },
];

const Routes = () => {
  const history = useHistory();
  const onAuthRequired = () => {
    history.push('/login');
  };
  useEffect(() => {
    if (window.location.hostname.startsWith('review')) {
      // Save the review app ID so the server knows where to redirect
      const reviewId = window.location.pathname.split('/')[1];
      sessionStorage.setItem('review', `/${reviewId}`);
    }
    usabilla();
  }, []);

  useEffect(() => {
    // @ts-expect-error Missing type for window
    window.usabilla_live?.('virtualPageView');
  }, [history]);

  useEffect(() => {
    checkLoggedIn();
    window.addEventListener('focus', checkLoggedIn);
    return () => {
      window.removeEventListener('focus', checkLoggedIn);
    };
  }, []);

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={onAuthRequired}
      restoreOriginalUri={
        config.oidc.restoreOriginalUri as RestoreOriginalUriFunction
      }
    >
      <Navigation />
      <Suspense fallback={<DelayLoader delay={1000} />}>
        <Switch>
          {routeDefinitions.map(({ secure, ...rest }, idx) => {
            const Component = secure ? SecureRoute : Route;
            return <Component key={(rest.path as string) || idx} {...rest} />;
          })}
        </Switch>
      </Suspense>
    </Security>
  );
};
export default Routes;
