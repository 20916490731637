import React from 'react';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import getNonAuthLDConfig from './getNonAuthLDConfig';

// eslint-disable-next-line react/jsx-no-useless-fragment
const FeatureFlagProvider: React.FC = ({ children }) => <>{children}</>;

const configObject = getNonAuthLDConfig();
const getProviderComponent = () =>
  withLDProvider(configObject)(FeatureFlagProvider);
export default getProviderComponent();
