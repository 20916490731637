import React from 'react';
import { JanusHookshotProvider, SwrOptions } from '@bw/hookshot';
import urlcat from 'urlcat';
import config from './utils/config';

const swrOptions: SwrOptions = {
  shouldRetryOnError: false,
  revalidateOnFocus: false,
};

const customFetch: typeof window.fetch = (input, init = {}) => {
  const request =
    typeof input === 'string'
      ? urlcat(config.irisApi, input)
      : {
          ...input,
          url: urlcat(config.irisApi, input.url),
        };

  return window.fetch(request, {
    ...init,
    headers: { 'Content-Type': 'application/xml', ...init.headers },
  });
};

/** Provides configured hookshot settings to passport. */
const ApiProvider: React.FC = ({ children }) => (
  <JanusHookshotProvider customFetch={customFetch} swrOptions={swrOptions}>
    {children}
  </JanusHookshotProvider>
);

export default ApiProvider;
