import { lazy } from 'react';

type ComponentFactory<T> = () => Promise<{ default: T }>;

export default function preloadLazy<T extends React.ComponentType<P>, P>(
  factory: ComponentFactory<T>,
): React.LazyExoticComponent<T> & {
  preload: ComponentFactory<T>;
} {
  const r = lazy(factory) as React.LazyExoticComponent<T> & {
    preload: ComponentFactory<T>;
  };
  r.preload = factory;
  return r;
}
