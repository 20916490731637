import { sha256 } from 'js-sha256';
import { ProviderConfig } from 'launchdarkly-react-client-sdk/lib/types';
import configValue from 'utils/config';

const getNonAuthLDConfig = (): ProviderConfig => ({
  clientSideID: configValue.launchDarkly.clientSideID,
  user: {
    key: configValue.launchDarkly.defaultUserKey,
  },
  options: {
    hash: sha256.hmac(
      configValue.launchDarkly.sdkKey,
      configValue.launchDarkly.defaultUserKey,
    ),
    bootstrap: 'localStorage',
    streaming: false,
    evaluationReasons: true,
  },
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
});
export default getNonAuthLDConfig;
